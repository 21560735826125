import axios from "axios";
import { method } from "lodash";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addSessionDataToStrapi({ getters }, sessionData) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://cms.lancing.arsconnect.com/user-sessions/",
        data: sessionData,
      };
      axios
        .request(config)
        .then((response) => {
          console.log("Session Data Added");
        })
        .catch((error) => {
          console.log("Session Data error", error.message);
        });
    },
  },
};
