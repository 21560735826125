import { ApolloClient } from 'apollo-client';
import axios from 'axios';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import * as fb from "@/firebase/index.js";
import {
    floorQuery,
    mapQuery,
    faqQuery,
    faqCategoryQuery,
    homepageQuery,
    departmentQuery
} from '@/graphql/query';

function today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return (mm + '-' + dd + '-' + yyyy);
}

export default {
    state: {
        dataLoaded: false,
        apolloClient: undefined,
        homepage: [],
        floors: [],
        maps: new Map(),
        faqs: [],
        faqCategory: [],
        fileCache: null,
        count: 0,
        modules: [],
        languages: [],
        kioskLocation: '',
        courts: [],
        defaultLocation: '',
        department: new Map()
    },
    getters: {
        getDataLoaded: state => state.dataLoaded,
        getModules: state => state.modules,
        getHomepage: state => state.homepage,
        getFloors: state => state.floors,
        getMaps: state => state.maps,
        getFaqs: state => state.faqs,
        getFaqCategories: state => state.faqCategory,
        getLanguages: state => state.languages,
        getKioskLocation: state => state.kioskLocation,
        getCourts: state => state.courts,
        getDefaultLocation: state => state.defaultLocation,
        getDepartment: state => state.department
    },
    mutations: {
        setDataLoaded(state, status) {
            state.dataLoaded = status;
        },
        setApolloClient(state, uri) {
            const fragmentMatcher = new IntrospectionFragmentMatcher({
                introspectionQueryResultData: {
                    __schema: {
                        types: [],
                    },
                },
            });
            state.apolloClient = new ApolloClient({
                link: new HttpLink({ uri: uri }),
                cache: new InMemoryCache({ fragmentMatcher })
            })
        },
        setModules(state, module) {
            state.modules.push(module)
        },
        setLanguages(state, lang) {
            state.languages.push(lang)
        },
        setkioskLocation(state, location) {
            state.kioskLocation = location
        },
        setHomepage(state, page) {
            console.log('Are homepages pulled: ', page)
            state.homepage.push(page)
        },
        setFloors(state, floor) {
            state.floors.push(floor)
        },
        setMaps(state, map) {
            state.maps.set(map.mapName.toLowerCase(), map)
        },
        setFaqs(state, faq) {
            state.faqs.push(faq)
        },
        setFaqCategory(state, category) {
            state.faqCategory.push(category)
        },
        setCourts(state, court) {
            state.courts.push(court)
        },
        setDefaultLocation(state, location) {
            state.defaultLocation = location
        },
        setDepartment(state, departmentinfo) {
            state.department = departmentinfo
        }
    },
    actions: {
        createApolloConnection({ commit }, uri) {
            return new Promise((resolve, reject) => {
                if (uri === '' || uri === null) reject('Empty or Invalid URI')
                try {
                    commit('setApolloClient', uri)
                    resolve('Apollo linked successfully');
                } catch (err) {
                    reject(err.message);
                }
            })
        },
        initiateDataPull({ commit, dispatch }, kioskData) {
            commit("setAvatarDetails", kioskData.data().avatarInfo);
            commit('setkioskLocation', kioskData.data().locationCode)
            dispatch('indentifyModuleLang', {
                module: kioskData.data().modules,
                language: kioskData.data().languages
            })
            commit("setDefaultLocation", kioskData.data().defaultLocation)
            
            //dispatch('updateAutoReferesh', kioskData)
        },
        indentifyModuleLang({ commit, dispatch }, data) {
            console.log('Modules to be included', data)
            commit('setModules', data.module)

            data.language.forEach(lang => {
                commit('setLanguages', lang)
            })
            dispatch('loadAvatar')
        },
        fetchHomePage({ commit, dispatch, state, getters }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: homepageQuery,
                        variables: {
                            "alias": getters.getModules,
                            "lang": lang
                        }
                    }).then(items => {
                        items.data.homepages.forEach(page => {
                            dispatch('saveInCache', page.menuIcon.url)
                                .then(response => {
                                    page.menuIcon.url = response
                                    commit('setHomepage', page);
                                })
                        })
                        resolve('Homepage fetched successfully.')

                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },
        // Fetch Floor from strapi
        fetchFloors({ commit, dispatch, state, getters }) {
            return new Promise((resolve, reject) => {
                console.log('Languages, location : ', getters.getLanguages, getters.getKioskLocation)
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: floorQuery,
                        variables: {
                            "location": getters.getKioskLocation,
                            "lang": lang
                        }
                    }).then(floors => {
                        floors.data.floors.forEach(floor => {
                            if (floor.floorImage !== null) {
                                dispatch('saveInCache', floor.floorImage.url)
                                    .then(response => {
                                        floor.floorImage.url = response;
                                        commit('setFloors', floor)
                                    })
                            }
                        })
                        resolve('Floor data fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })

                })
            })
        },

        // Fetching Maps from Strapi
        fetchMaps({ commit, state, dispatch, getters }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: mapQuery,
                        variables: {
                            "location": getters.getKioskLocation,
                            "lang": lang.code
                        }
                    }).then(maps => {
                        maps.data.maps.forEach(map => {
                            if (map.mapImage !== null) {
                                dispatch('saveInCache', map.mapImage.url)
                                    .then(response => {
                                        commit('setMaps', {
                                            mapName: map.mapName,
                                            mapFloor: map.mapFloor,
                                            mapImage: response,
                                            speech: map.speech,
                                            locale: map.locale
                                        })
                                    })
                            }
                        })
                        resolve('Map data fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },


        // --------------------------------------------------------- Fetch Data related to Faqs Page -----------------------------------------

        fetchFaqs({ commit, state, getters, dispatch }) {
            return new Promise((resolve, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: faqQuery,
                        variables: {
                            "lang": lang.code,
                            "location": getters.getKioskLocation
                        }
                    }).then(faqs => {
                        //commit('setFaqs', faqs.data.faqs);
                        faqs.data.faqs.forEach(faq => {
                            if (faq.faqType[0].__typename === "ComponentFaqDisplayExpansionPanel") {
                                let data = {
                                    question: faq.faqType[0].question,
                                    answer: faq.faqType[0].answer.replace(/\n/g, '<br />'),
                                    category: faq.category,
                                    showMap: faq.showMap,
                                    mapName: faq.mapName,
                                    displayType: 'panel',
                                    locale: faq.locale
                                }
                                commit('setFaqs', data)
                            } else {
                                dispatch('saveInCache', faq.faqType[0].guide.url)
                                    .then(response => {
                                        let data = {
                                            guideName: faq.faqType[0].guideName,
                                            guideLink: faq.faqType[0].guideLink,
                                            guideUrl: response,
                                            category: faq.category,
                                            displayType: 'button',
                                            locale: faq.locale
                                        }
                                        commit('setFaqs', data)
                                    })
                            }
                        })
                        resolve('FAQs fetched successfully.')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },

        fetchFaqCategory({ commit, state, getters }) {
            return new Promise((response, reject) => {
                getters.getLanguages.forEach(lang => {
                    state.apolloClient.query({
                        query: faqCategoryQuery,
                        variables: {
                            "lang": lang.code,
                            "location": getters.getKioskLocation
                        }
                    }).then(faqCategory => {
                        faqCategory.data.faqCategories.forEach(category => {
                            console.log('FAQ Category: ', category)
                            commit('setFaqCategory', category)
                        })

                        response('FAQ categories fetched successfully')
                    }, error => {
                        reject(error.message)
                    })
                })
            })
        },

        fetchDepartmentContacts({ commit, state }) {
            return new Promise((response, reject) => {
                var tempMap = new Map()
                state.apolloClient.query({
                    query: departmentQuery
                }).then(department => {
                    console.log('Fetching Departments: ', department.data)
                    department.data.directories.forEach(depart => {
                        if (tempMap.has(depart.contactDepartment)) {
                            // get the department and push the value to 
                            let arr = tempMap.get(depart.contactDepartment)
                            arr.push(depart)
                            tempMap.set(depart.contactDepartment, arr)
                        } else {
                            tempMap.set(depart.contactDepartment, [depart])
                        }
                    })
                    commit('setDepartment', tempMap)
                    response('Directory Data Fetched fetched successfully')
                }, error => {
                    reject(error.message)
                })
            })
        },

        // --------------------------------- Data Caching (Image, PDF) ------------------------------------------------------------
        saveInCache({ state, getters }, path) {
            return new Promise((response, reject) => {
                state.fileCache = caches.open('fileCache')
                    .then(cache => {
                        cache.match(getters.getCMSlink + path)
                            .then(cacheResponse => {
                                if (cacheResponse) {
                                    return (cacheResponse.blob())
                                        .then(blob => {
                                            response(URL.createObjectURL(blob))
                                        })
                                } else {
                                    cache.add(getters.getCMSlink + path)
                                    cache.match(getters.getCMSlink + path)
                                        .then(cacheResponse => {
                                            return (cacheResponse.blob())
                                                .then(blob => {
                                                    response(URL.createObjectURL(blob))
                                                })
                                        })
                                }
                            })
                    })
            })
        },

        // ---------------------------------------------- Odessey --------------------------------------------------------------

        searchOdysseybyName({ state }, keyword) {
            return new Promise((response, reject) => {
                fb.odysseyCollection
                    .where("courtLocation", "==", "roswell")
                    .where("dateCreated", "==", today())
                    .where("partyOneName", "==", keyword.toLowerCase())
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) reject('Sorry I did not find any results for this user name.')
                        response(querySnapshot);
                    })
            })
        },
        searchOdysseybyCaseNo({ state }, caseNumber) {
            return new Promise((response, reject) => {
                fb.odysseyCollection
                    .where("courtLocation", "==", "roswell")
                    .where("dateCreated", "==", today())
                    .where("caseNo", "==", caseNumber)
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) reject('Sorry I did not find any results for this case number.')
                        response(querySnapshot);
                    })

            })
        },
        searchByCaseNo({ state }, caseNumber) {
            return new Promise((response, reject) => {
                let config = {
                    method: 'get',
                    url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
                    params: {
                        caseNumber: caseNumber,
                        courtName: 'fifth judicial district chaves county'
                    }
                };

                axios(config)
                    .then(result => {
                        //   console.log('DATA X CHANGE: ', result.data)
                        response(result.data);
                    })
                    .catch(function (error) {
                        // console.log(error);
                        reject(error)
                    });

            })
        },
        searchByName({ state }, nameObject) {
            return new Promise((response, reject) => {
                let parameter;
                if (nameObject.middleName === '' || nameObject.middleName === null) {
                    parameter = {
                        firstName: (nameObject.firstName.toLowerCase()).trim(),
                        lastName: (nameObject.lastName.toLowerCase()).trim(),
                        courtName: 'fifth judicial district chaves county'
                    }
                }
                else {
                    parameter = {
                        firstName: (nameObject.firstName.toLowerCase()).trim(),
                        lastName: (nameObject.lastName.toLowerCase()).trim(),
                        middleName: (nameObject.middleName.toLowerCase()).trim(),
                        courtName: 'fifth judicial district chaves county'
                    }
                }
                let config = {
                    method: 'get',
                    url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
                    params: parameter
                };

                axios(config)
                    .then(result => {
                        response(result.data);
                    })
                    .catch(function (error) {
                        // console.log(error);
                        reject(error)
                    });

            })
        }
    }
}
