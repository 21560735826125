import { db } from "@/firebase"
import i18n from "../../i18n";
export default {
  state: {
    contentFetched: false,
    appDimensions: {},
    pageHeader: null,
    pdfViewer: {},
    imageViewer: {},
    emailViewer: {},
    optionsViewer: {},
    courtTimingViewer: {},
    // user session handling (Displayed on Dashboard App)
    startUserSession: undefined,
    endUserSession: undefined,
    userQnaRequest: [],
    userSession: {},
    sessionEndViewer: false,
    //Default Options:
    selectedFaqCat: {
      name: "Appeal",
      type: "expansionPanel"
    },
    selectedFormCat: {
      category: "childrens_court",
      showTab: true
    },
    formCatListHeight: 518
  },
  getters: {
    contentFetched: state => state.contentFetched,
    appDimensions: state => state.appDimensions,
    getPageHeader: state => state.pageHeader,
    getPdfViewer: state => state.pdfViewer,
    getImageViewer: state => state.imageViewer,
    getEmailViewer: state => state.emailViewer,
    getOptionsViewer: state => state.optionsViewer,
    getCourtTimingViewer: state => state.courtTimingViewer,
    getStartUserSession: state => state.startUserSession,
    getEndUserSession: state => state.endUserSession,
    getUserQnaRequest: state => state.userQnaRequest,
    getSessionEndViewer: state => state.sessionEndViewer,
    getSelectedFaqCat: state => state.selectedFaqCat,
    getSelectedFormCat: state => state.selectedFormCat,
    getFormCatListHeight: state => state.formCatListHeight,
  },
  mutations: {
    setAppDimensions(state) {
      let newWidht = window.innerWidth;
      let newHeight = (1080 / 1920) * window.innerWidth;
      state.appDimensions = {
        width: newWidht,
        height: newHeight
      }
    },
    setPageHeader(state, value) {
      state.pageHeader = value;
    },
    setPdfViewer(state, pdfObj) {
      state.pdfViewer = pdfObj;
    },
    closePdfViewer(state) {
      state.pdfViewer = { show: false }
    },
    setImageViewer(state, imgObj) {
      state.imageViewer = imgObj
    },
    closeImageViewer(state) {
      state.imageViewer = { show: false }
    },
    setEmailViewer(state, emailObj) {
      state.emailViewer = emailObj
    },
    closeEmailViewer(state) {
      state.emailViewer = { show: false }
    },
    setOptionsViewer(state, optionsObj) {
      state.optionsViewer = optionsObj
    },
    closeOptionsViewer(state) {
      state.optionsViewer = { show: false }
    },
    setCourtTimingViewer(state, courtObj) {
      state.courtTimingViewer = courtObj
    },
    closeCourtTimingViewer(state) {
      state.courtTimingViewer = { show: false }
    },
    setStartUserSession(state, startUserSession) {
      state.startUserSession = startUserSession
    },
    setEndUserSession(state, endUserSession) {
      state.endUserSession = endUserSession
    },
    setUserQnaRequest(state, userQnaRequest) {
      state.userQnaRequest.push(userQnaRequest)
    },
    setSessionEndViewer(state, status) {
      state.sessionEndViewer = status
    },
    setSelectedFaqCat(state, faqVal) {
      state.selectedFaqCat.name = faqVal.name
      state.selectedFaqCat.type = faqVal.type
    },
    setSelectedFormCat(state, formVal) {
      state.selectedFormCat = formVal
    },
    setFormCatListHeight(state, height) {
      state.formCatListHeight = height
    }
  },
  actions: {
    openPdfViewer({ commit }, pdfObj) {
      pdfObj.show = true;
      commit('setPdfViewer', pdfObj)
    },
    openImageViewer({ commit }, imgObj) {
      imgObj.show = true
      commit('setImageViewer', imgObj)
    },
    openEmailViewer({ commit }, emailObj) {
      emailObj.show = true
      commit('setEmailViewer', emailObj)
    },
    openOptionsViewer({ commit }, optionsObj) {
      optionsObj.show = true
      commit('setOptionsViewer', optionsObj)
    },
    openCourtTimingViewer({ commit }, courtObj) {
      courtObj.show = true
      commit('setCourtTimingViewer', courtObj)
    },
    setDefaults({ commit, getters }) {
      if(getters.getFaqCategories.length > 0){
        commit('setSelectedFaqCat', { name: i18n.t('faqCategory')[0].categoryName, type: i18n.t('faqCategory')[0].displayType });
      }
    }
  }
}
